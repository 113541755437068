.ProductPersonalisationEmboss {
  width: 600px;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 15px;
  border-radius: 4px;

  .header{
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .hlistItem{
    margin-right: 15px;
  }

  .productionNotes{
    margin-bottom: 30px;
    color: #f44336;
    //color: white;
    padding: 5px;
    font-weight: bold;
    margin-top: 20px;
  }

  .status {
    cursor: pointer;
    padding: 4px;
    background: #f44336;
    color: #fff;

    &.done {
      background: #66bb6a;
    }
  }

  .textPos {
    display: flex;
    margin-bottom: 10px;
  }

  .fill {
    flex:1;
  }

  .lines {
    //margin-top: 30px;
  }

  .line {
    margin-bottom: 20px;
  }

  .lineInfo {
    font-style:italic;
    color: #909090;
    padding-top: 5px;
    padding-left:3px;
  }
}
