.ShippmentItems {
  display: flex;
  flex-direction: column;
  border: 1px solid black;


  > .title {
    font-weight: bold;
    color: white;
    background: black;
    display: initial;
    padding: 5px;
    font-size: 16px;
  }

  > .items {

    > .item {

    }
  }

  > .footer{
    display: flex;
    align-items: center;
    margin: 10px;

    > .invoice {
      flex:1;
    }
  }
}
