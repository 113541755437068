.MaterialEditor {
    margin-left: 20px;

    > .row {
        display: flex;
        margin-bottom: 30px;

        > .item {
            margin-right: 20px;
        }
    }

    > .images {
        display: flex;

        > .image {
            margin: 0 10px;
            display: flex;
            flex-direction: column;

            > .checkmark {
                background: white;
                display: inline-flex;
                padding: 2px;
                position: absolute;
                z-index: 1000;
                margin-top: 50px;
                margin-left: 49px;
            }

            > .image-wrapper {
                flex:1;

                > .image-element {
                    width: 100px;
                    height: auto;
                }
            }
        }
    }

    > .save {
        display: flex;
        justify-content: flex-end;
        flex:1;

        > .save-button{

        }
    }
}