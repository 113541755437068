.GalleryItem {
    padding: 30px;
    background: #f7f7f7;
    width: 1000px;
    border-radius: 8px;

    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin: 50px 0;
    margin-left: 60px;

    > .save{
        margin-top: 20px;
    }

    > .editing{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;


        .item {
            margin: 10px;

            > .label {
                padding: 3px
            }
        }
    }

    .deleteButtonStyle{
        position: absolute;
        background: rgb(244, 67, 54);
        color: white;
        border-radius: 40px;
        width: 20px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        right: -10px;
        top: -10px;
    }
}