.OrdersTabNavigator {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  border-bottom: 1px solid #dfdfdf;


  > .leftSide {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 10px;

    > .refresh{
      display: flex;
      flex:1;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    > .buttons {
      display: flex;
      width: 100%;
      height: 80px;

      > .button{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 72pt;
        cursor: pointer;
        color: #696969;
        width: 50%;
        height: 78px;
        border: 1px solid #eaeaea;

        &:hover{
          border: 1px solid #606060;
        }
      }
    }
  }

  > .rightSide {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    flex: 1;
    //background: #dbdbdb;
    overflow-y: scroll;
    margin-bottom: 10px;

    > .orderItems {
      width: 100%;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      padding: 0 10px 10px;
      margin-top: 10px;

      > .orderItem {
        //border: 1px solid rgba(34, 36, 38, .15);
        border-top: none;
        border-bottom: none;
        border-left: none;
        display: flex;
        align-items: center;
        width: 200px;
        height: 40px;
        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 16px;
        margin-bottom: 46px;
        background: #0000000f;

        &.selected{
          border: 2px solid black;
        }

        > .orderId {
          margin-left: 3px;
          font-size: 18px;
          font-weight: bold;
          width: 110px;
        }

        > .client {
          color: #5d5c5ccc;
          text-align: left;
          font-size: 12px;
          margin-right: 3px;
          width: 90px;
        }

        .arrow {
          position: relative;
          right: -12px;
          color: #5d5c5ccc;
        }

        &:hover {
          border-bottom: 3px solid black;
        }
      }
    }
  }
}
