.ConsumptionPanel {
  margin-top: 20px;

  .consumptionItem {

    display: flex;
    align-items: baseline;
    margin-bottom: 5px;

    > .title {
      font-weight: bold;
    }

    > .consumed {
      margin-left: 5px;
      opacity: 0.7;

      > .notInStoc {
        color: #F44336;
        padding: 3px 6px;
        font-weight: bold;
        font-size: 13px;
      }

      > .inStoc {
        color: #169520;
        padding: 3px 6px;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}
