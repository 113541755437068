.PersonalisationScreen {
  .list{
    margin: 20px 0;
  }
  .headbar {
    display: flex;
  }
  .searchField {
    margin-bottom: 30px;
  }

  .tabStatus{
    font-size: 12px;
    margin-left:5px;
    background: #f44336;
    color: white;
    padding: 5px;

    &.done {
      background: #4CAF50;
    }
  }
}
