.ExternalOrder{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;

    &.late {
        box-shadow: 0 1px 3px 2px #F44336, 0 1px 2px #F44336;
    }

    &.closing{
        box-shadow: 0 1px 3px 2px #FFEB3B, 0 1px 2px #FFEB3B;
    }

    > .left {
        width: 150px;
        > .title {
            font-weight: bold;
            font-size: 18px;
        }

        > .deadline {
            margin-top: 5px;
        }

        > .source {
            color: gray;
        }
    }

    > .right {
        display: flex;
        margin-left: 20px;
        flex:1;

        > .field {
            padding: 0 20px;

            > .label {
                font-size: 13px;
                color: #424141;
            }

            > .value{
                font-weight: bold;
            }
        }
    }

    > .status {
        > .item {
            background: #F44336;
            color: white;
            font-weight: bold;
            padding: 0 3px 1px 3px;
            border-radius: 4px;
            cursor: pointer;
            margin-bottom: 4px;
            text-align: center;

            &.green {
                background: #4caf50;
            }
        }
    }
}