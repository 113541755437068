.MonthlyProfitComponent {
  margin-top: 100px;

  > .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  > .download{
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 30px;
  }
}