.NewOrderScreen {
  > .zone {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 15px;

    > .title{
      font-weight: bold;
      font-size: 16px;
    }
  }
}