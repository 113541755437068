.EtsyAccountingLedgerScreen {
    margin-top: 50px;

    > .Item{
        padding: 10px;
    }

    > .Download {
        position: fixed;
        left: 100px;
        top: 100px;
    }
}
