//$border: 1px solid black;
$border: none;

.WorkItem {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 20px;
  font-size: 15px;

  &.linked{
    box-shadow: none;
  }

  > .header {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    background: #e0e0e0;
    align-items: center;

    > .orderid{
      font-weight: bold;
      font-size: 17px;
      width: 130px;
    }

    > .client{
      width: 200px;
    }

    > .deadline {
      font-weight: bold;
    }

    > .external {
      margin-left: 40px;
      background: blue;
      color: white;
      padding: 2px;
      font-weight: bold;
      border-radius: 4px;
    }

    .ready {
      margin-left: 100px;
    }
  }

  > .row {
    display: flex;
    padding: 20px;
    font-size: 15px;

    > .id {
      border: $border;
      width: 170px;
    }

    > .type {
      border: $border;
      width: 130px;
      font-weight: bold;
    }

    > .qty {
      border: $border;
      width: 60px;
      font-weight: bold;
    }

    > .marime {
      border: $border;
      width: 80px;
    }

    > .colaje {
      border: $border;
      width: 80px;
    }

    > .coperta {
      border: $border;
      width: 200px;
    }

    > .material {
      border: $border;
      width: 150px;
    }

    > .uv {
      border: $border;
      width: 70px;
    }

    > .emboss {
      border: $border;
      width: 100px;
    }

    > .rezerva {
      border: $border;
      width: 100px;
    }

    > .colturi {
      border: $border;
      width: 80px;
    }

    > .pages {
      border: $border;
      width: 150px;
    }

    .fill {
      flex:1
    }
  }

  > .table {
    display: flex;
    font-size: 15px;
    padding: 20px;
    margin-left: 10px;
    border-radius: 6px;
    margin-bottom: 5px;



    > .production-status {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
    }
  }
}