.OrderItem_Awb {
  > .create-icon{
    font-size: 25px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: #8e8e8e;
    margin-left: 3px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  > .warning{
    background: red;
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  > .awb-info {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 15px;

    > .created{
      font-weight: bold;
      margin-right: 50px;
    }

    > .estimated {
      margin-top: 20px;
      margin-right: 50px;
      width: 300px;

      > .total {
        font-weight: bold;
        margin-top: 10px;
      }
    }

    > .download{
      margin-top: 20px;
      flex:1;

      > .click {
        font-weight: bold;
        cursor: pointer;
      }
    }

    > .delete {
      color: red;
      cursor: pointer;
    }

    > .user {
      margin-right: 50px;
      width: 200px;

      > .name {
        font-weight: bold;
      }

      > .packages {
        margin-top: 10px;
      }
    }
  }
}