.OrderItemStatus{
    display: flex;

    > .step {
         margin-left: 10px;
         padding: 1px 5px;
         font-weight: bold;
         cursor: pointer;


        &.large{
            font-size: 16px;
            padding: 3px;
        }

        &.none{
            background: #F44336;
            color: white;
        }

        &.progress{
            background: #ffeb3b;
            color: black;
        }

        &.done{
            background: #66bb6a;
            color: white;
        }

        &.inStock{
            background: #44b5ee;
            color: white;
        }

        &.inStockPotential{
            background: linear-gradient(-90deg, rgba(68,181,238,1) 0%, rgba(244,67,54,1) 50%);
            color: white;
        }
    }
}
