.ShippingScreenBatch {
    .orderLabel {
        white-space: pre-wrap;
        color: black;

        &.red{
            color: red;
        }
    }

    .title{
        background: black;
        display: inline;
        color: white;
        padding: 6px;
    }
}