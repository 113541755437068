.SalesScreen {
  display: inline-flex;
  flex-direction: column;

  > .modal {
    background: #1d5d90;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 100;
    color: white;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .item {
    padding: 24px;
    display: inline-flex;
    flex-direction: column;
    border: 1px solid #dadada;
    border-radius: 8px;
    margin-bottom: 80px;

    > .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    > .description {
      color: #797979;
      margin-bottom: 12px;
    }

    > .actions {
      display: flex;
    }

  }
}
