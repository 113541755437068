.EtsySyncStats {
  > .title {
    font-weight: bold;
  }

  > .content {
    display: flex;
    font-size: 12px;
    margin: 5px 0 0 0;

    > .item {
      display: flex;
      margin-right: 10px;

      > .name {
        //margin-right: 5px;
        background: black;
        color: white;
        padding: 0 5px;
      }

      > .value {
        color: white;
        padding: 0 5px;

        &.red {
          background: #f44336;
        }

        &.green {
          background: #4baf4f;
        }

        &.blue {
          background: #2185d0;
        }

        &.button{
          cursor: pointer;
        }
      }
    }
  }
}