.OrderStatusToggler {
  > .status {
    font-size: 16px;
    font-weight: bold;
    border: 1px solid black;
    display: inline-table;
    padding: 5px;
    cursor: pointer;

    &.small {
      font-size: 12px;
      border: none;
      border-radius: 5px;
      padding: 1px 3px 1px 3px;
    }

    &.red {
      background: red;
      color: white;
    }

    &.turqoise {
      background: #00b5ad;
      color: white;
    }

    &.green {
      background: #4CAF50;
      color: white;
    }

    &.blue {
      background: #2185d0;
      color: white;
    }

    &.orange {
      background: #f2711c;
      color: white;
    }

    &.black {
      background: black;
      color: white;
    }

    &.purple {
      background: purple;
      color: white;
    }

    &.gray {
      background: #999999;
      color: white;
    }

  }


  > .options {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 12px;
    position: absolute;
    background: white;
    z-index: 100;

    > .status {
      font-size: 16px;
      padding: 5px;
      cursor: pointer;

      &.hover {
        font-weight: bold;
      }

    }
  }
}
