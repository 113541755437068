.DhlCheckScreen {
  margin: 3rem;

  > .add {
    > .container {
      margin-top: 20px;
    }
  }

  //.item:nth-child(even) {
  //  background: #CCC
  //}

  > .data {


    > .item {
      border-bottom: 1px solid #d5d5d5;

      > .content {

        display: flex;
        align-items: center;
        padding: 3px 5px;
        margin: 10px 0;

        > .data {
          width: 150px;
          padding: 0 15px;

          > .label {
            color: #757575;
            font-size: 12px;
          }

          > .value {
            &.bold {
              font-weight: bold;
            }

            &.button {
              cursor: pointer;
              color: #03A9F4;
            }
          }
        }

        > .dot {
          width: 15px;
          height: 15px;
          background: red;
          border-radius: 10px;
          margin-right: 10px;

          &.notFound {
            background: #2196F3;
          }

          &.delta {
            background: #F44336;
          }

          &.valid {
            background: #4CAF50;
          }
        }
      }
    }
  }
}