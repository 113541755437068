.Order2AwbScreen{
  .isRemake {
    display: inline-flex;
    background: #a333c8;
    color: white;
    padding: 1px 5px;
  }

  .isSuborder {
    display: inline-flex;
    background: #3367c8;
    color: white;
    padding: 1px 5px;
  }

  .isLate {
    color: red;
  }

  .trackingParent {
    display: flex;

    > .trackingWho{
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
