.SuppliersScreen {

  .create-icon {
    font-size: 25px;
    cursor: pointer;
    margin: 20px;
  }

  .cancel-icon {
    font-size: 25px;
    cursor: pointer;
    margin: 20px;
    color: red;
  }

  .create-product {
    display: flex;
    align-items: center;
  }

  .margin-left {
    margin-right: 8px;
    margin-top: 8px;
  }

  > .container {
    margin: 3rem 0;

    > .table-display {
      margin: 50px 0;
    }
  }

}