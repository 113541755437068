.OrderUser {
  width: 250px;

  .head {
      display: flex;
      margin-bottom: 10px;
      padding: 4px;
      border-bottom: 2px solid #dededf;

    > .text {
      font-size: 15px;
        font-weight: bold;
    }

    > .fill {
      flex: 1;
    }

    > .change {
      color: rosybrown;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }

  .extra {
    margin: 10px 0;
    padding: 0 5px;

      .link{
          cursor: pointer;
      }
  }

  .body {
    margin: 10px 0;
    padding: 0 5px;

    > .header {
      font-size: 16px;
      line-height: 1.28571429em;
    }

    > .underheader {
      color: #bababc;
    }
  }

  .expanded-order {
    display: flex;
    margin-left: 10px;
    margin-bottom: 3px;

    > .id {
      margin: 0 5px 0 0;
    }

    > .date {
      margin-right: 10px;
    }

  }
}