.TaieriScreen {
  padding: 100px 0;

  > .menuHead {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    border: 2px solid #757575;
    border-bottom: none;
    margin: 10px;
    margin-bottom: 0;
  }

  > .newCut{
    display: flex;
    justify-content: center;
    border: 2px solid #757575;
    padding: 35px;
    border-top: none;
    margin: 10px;
    margin-top:0;

    > .arrow{
      margin: 0 20px;
      font-size: 100px;
      color: #888b8d;
      margin-top: 65px;
    }

    > .results{
      display: flex;
      flex-wrap: wrap;
      //flex-direction: column;
      align-content: center;
    }
  }


}
