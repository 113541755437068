.main {
  display: flex;
  margin: 3rem;

  > .left {
    padding: 15px;
    width: 250px;

    .infoPiece {
      margin-bottom: 20px;
    }

    .leftTitle {
      font-size: 16px;
      color: white;
      background: black;
      padding: 3px;
      margin-bottom: 8px;
    }

  }

  > .right {
    margin-left: 10px;
    padding: 15px;
    flex: 1;

     .row {
      display: flex;
    }

    .orderItems {
      margin-top: 40px;
      //display: flex;

      > .mainOrderSelector {
        display: flex;

        >.checkbox-suborder{
          margin-right: 5px;
        }

        > .mainOrderSelectorStatus{
          margin: 0 5px;
        }
      }
    }

    .selectionItem {
      margin-right: 20px;

      > .title {
        font-weight: bold;

        &.red {
          padding: 5px;
          background: #f56d6d;
        }
      }

      > .items {
        display: flex;

        > .item {
          font-size: 16px;
          margin-right: 10px;
          margin-top: 5px;
          border: 1px solid #b2b2b2;
          padding: 3px 6px;
          cursor: pointer;
          user-select: none;

          &.selected {
            background: #8bc34aba;
          }

          &.selectedOrder {
            background: rgba(74, 195, 191, 0.73);
          }
        }
      }
    }
  }

  .order {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 30px;
  }

}

.FilledItem {
  margin-bottom: 50px;
}

.FilledItemTitle {
  margin-bottom: 0;
  padding: 5px 5px;
  color: black;
  background: #e0e0e0;
}

.FilledItemBody {
  padding: 15px 10px;
  border: 1px solid #e0e0e0;
  border-top: none;
}
