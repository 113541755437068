.ManufactureScreen {

    > .table-item {
        display: flex;
        font-size: 15px;
        padding: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 6px;
        margin-bottom: 5px;

        > .row {

        }
    }

    > .orders {

        > .header {
            font-size: 2rem;

            padding: 10px;

            font-weight: bold;

            &.urgent {
                background: #F44336;
                color: white;
            }

            &.todo {
                background: #367ef4;
                color: white;
            }

            &.done {
                background: #4CAF50;
                color: white;
            }
        }
    }

}

.custom-tooltip {
    background: white;
    padding: 12px;

    > .total {
        font-weight: bold;
    }
}
