.FulfillerFilter {
  display: inline-flex;
  flex:1;
}

.FulfillerFilterItem {
  display: flex;
  width: 45px;
  height: 36px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-weight: bold;
  border-radius: 5px;
  margin: 0 5px;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.selected{
   border: 2px solid black;
  }

  &.hidden {
    display: none;
  }
}
