.SupplierProductConfig{
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    > .title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 15px;
    }

    > .body{
        margin-left: 30px;

        > .save-button{
            margin: 15px 0;
        }
    }


}