.PendingOrder {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;

    > .header {
        display: flex;
        width: 300px;

        > .name {
            > .date {
                color: gray;
            }

            > .client {
                font-weight: bold;
                font-size: 16px;
                margin: 5px 0;
            }

            > .cost {
                display: flex;

                > .currency {
                    margin: 0 5px 0 0;
                }

                > .amount {

                }
            }

            > .status {
                display: inline-flex;
                background: red;
                color: white;
                font-weight: bold;
                padding: 5px;
                margin: 9px 0;

                &.green {
                    background: #4caf50;
                }
            }
        }
    }

    > .details {
        > .line {
            padding: 5px 0;
        }
    }
}