.WebsiteSwatchComponent {
  .placeholder {
    opacity: 0.2;
  }

  > .modeSelector {
    display: flex;
    margin: 15px 0;
    column-gap: 12px;
  }

  > .materialSelector {
    display: flex;
    flex-wrap: wrap;

    > .materialItemSelectMode {
      width: 150px;
      height: 150px;
      margin: 20px 10px;

      > .materialThumb {
        cursor: pointer;
        width: 100%;
        height: auto;

        padding: 5px;
        border: 5px solid transparent;

        &.selected{
          border: 5px solid black;
        }
      }
    }
  }

  .materialItemReorderMode {
    width: 150px;
    height: 150px;
    margin: 20px 10px;

    > .materialItemThumbReorderMode {
      cursor: pointer;
      width: 100%;
      height: auto;

      padding: 5px;
      border: 5px solid transparent;
    }
  }

  .dragged {
    z-index: 1000;
  }

  > .tabNames {
    display: flex;
    flex-direction: column;

    > .editTabItem {
      display: flex;
      margin: 5px;
      column-gap: 12px;
    }
  }

}