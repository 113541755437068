.AwbCreate {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  padding: 15px;
  border-radius: 5px;

  > .head {
    display: flex;

    > .title {
      font-weight: bold;
      font-size: 20px;
      flex:1;
    }

    > .cancel{
      color: red;
      cursor: pointer;
    }
  }

  .estimated{
    margin-top: 30px;
  }

  .error {
    margin-left: 30px;
    margin-top: 30px;
    font-size: 15px;
    color: red;
    font-weight: bold;
    white-space: pre-line;
  }
}