.ShippingScreenBatchAwb {
    .orderLabel {
        white-space: pre-wrap;
        color: black;
    }

    .awbMarker{
        writing-mode: vertical-lr;
        transform: scale(-1, -1);
        text-align: center;
    }

}



.ui.table tfoot th{
    background: white;
}