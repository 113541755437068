.PopOverIconRenderer{
    > .over {
        background: white;
        position: relative;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        padding: 11px;
        width: 450px;

        margin-top: 5px;
        white-space: pre-line;
        margin-left: 50px;
    }
}