.AwbAddress {
    width: 500px;


    > .edit-icon{
        cursor: pointer;
     }


    .row {
        display: flex;
        flex-wrap: wrap;

        > .autocomplete {
            position: absolute;
            margin-top: 114px;
            margin-left: 78px;
            background: white;
            padding: 10px;
            border: 1px solid #dededf;
            width: 427px;
            z-index: 500;

            > .autocomplete-item {
                cursor: pointer;
                padding: 3px 0;

                &:hover{
                    font-weight: bold;
                }
            }
        }

        .item {
            margin: 5px;

            &.fill {
                flex: 1;
            }

        }
    }
}