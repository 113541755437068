.OrderProduction {
  > .item {
    //display: flex;
    margin: 20px 0;
    padding-top: 20px;
    border-top: 1px solid gray;


    > .title {
      background: black;
      display: inline-table;
      padding: 5px;
      color: white;
      font-weight: bold;
      margin-right: 15px;
    }

    > .content {
      display: flex;
      flex-wrap: wrap;
      > .part {
        padding: 10px;
        margin: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

        > .title {
          margin-bottom: 15px;
          font-weight: bold;
        }

        > .values {
          display: flex;

          > .value {
            margin-right: 15px;

            > .label {
              font-size: 10px;
            }

            > .value {
              margin-top: -5px;
            }
          }
        }

        > .match {
          padding-top: 5px;
          border-top: 1px solid lightgray;
          margin-top: 15px;
        }
      }
    }
  }
}