.OrderItem_Items {
  .tro-input {
    margin: 10px;
  }

  > .items {
    display: flex;

    > .left {
      width: 300px;

        > .message-buyer {
            maxWidth: 300px;
            margin: 20px;

            > .title {
                font-weight: bold;
                background: black;
                color: white;
                padding: 5px;
                display: inline-block;
                margin-bottom: 10px;
            }

            > .message{
                whiteSpace: pre-wrap;
            }
        }

        > .external-items {
            maxWidth: 300px;
            margin: 20px;
            margin-bottom: 30px;

            > .title {
                font-weight: bold;
                background: black;
                color: white;
                padding: 5px;
                display: inline-block;
                margin-bottom: 10px;
            }

            > .message{
                whiteSpace: pre-wrap;
            }
        }
    }

    > .right {
      flex: 1;

      > .quickFillButton{
        display: inline-flex;
        font-size: 16px;
        margin-right: 10px;
        margin-top: 5px;
        border: 1px solid #b2b2b2;
        padding: 3px 6px;
        cursor: pointer;
        background: rgba(74, 195, 191, 0.73);
        margin-bottom: 15px;
      }
    }
  }

  > .production-deadline {
    display: flex;
    margin: 0 0 20px 20px;
    align-items: center;

    > .title {
      font-weight: bold;
      margin-right: 10px;
    }

    > .mr {
      margin-right: 10px;
    }

    > .actions{
      display: flex;
      flex:1;
      justify-content: flex-end;

      > .merge {
        margin: 0 10px;
        cursor: pointer;
      }

      > .cancel {
        margin: 0 10px;
        cursor:pointer;
      }
    }
  }

  .react-date-picker__wrapper {
    //display: flex;
    border: thin solid #dededf;
    padding: 6px;
    border-radius: 5px;
  }
}
