.OrderFinance {
  width: 300px;


  > .head {
    display: flex;
    margin-bottom: 10px;
    padding: 4px;
    border-bottom: 2px solid #dededf;

    > .download {
      margin-left: 20px;
      cursor: pointer;
      color: #4183c4;
    }

    > .text {
      font-size: 15px;
      font-weight: bold;
    }

    > .change {
      color: #83bc70;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: green;
      }
    }
  }

  .extra {
    margin: 10px 0;
    padding: 0 5px;

    > .add-finance {
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      > .content {

        > .amount {
          display: flex;
          margin: 5px 0;
        }

        > .buttons {
          margin-top: 10px;
        }

        > .checkbox-option {
          margin: 7px 0 15px 0;
        }
      }
    }

    .link {
      cursor: pointer;
    }

    > .finance-item {
      border-bottom: 1px solid #dededf;
      margin: 15px 0;

      > .head {
        display: flex;

        > .type {
          margin-left: 8px;
          font-weight: bold;
        }

        > .amount {
          font-weight: bold;
          margin-right: 10px;

          &.green {
            color: green;
          }

          &.red {
            color: red;
          }

          &.blue {
            color: blue;
          }
        }

        > .delete {
          font-weight: bold;
          color: rosybrown;
          font-size: 16px;
          cursor: pointer;

          &:hover {
            color: red;
          }
        }
      }

      > .invoices {
        margin-left: 20px;
        margin-top: 30px;

        > .invoice {
          margin: 5px 0;

          &.stornated {
            text-decoration: line-through;
          }

          > .head {
            display: flex;

            > .create {
              margin-right: 10px;
              color: gray;
              cursor: pointer;

              &:hover {
                color: black;
              }
            }

            > .attach {
              margin-right: 10px;
              color: gray;
              cursor: pointer;

              &:hover {
                color: black;
              }
            }

            > .name {
              font-weight: bold;
              margin-right: 20px;
            }

            > .delete {
              font-weight: bold;
              color: rosybrown;
              font-size: 12px;
              margin-left: 10px;
              cursor: pointer;

              &:hover {
                color: red;
              }
            }
          }

          > .details {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            margin-left: 10px;

            > .info {
              margin-right: 8px;
            }
          }
        }
      }

      > .info {
        margin-left: 12px;
        font-size: 12px;
        color: gray;
      }

      > .taxes {
        font-size: 12px;
        margin: 8px 0 0 20px;
        //margin-left: 10px;

        > .tax-item {
          display: flex;

          &.gray{
            color: gray;
            margin-bottom: 10px;
          }

          > .name {
            width: 80px;
            //text-align: right;
            margin-right: 20px;
          }

          > .value {

          }
        }
      }
    }
  }

  .body {
    margin: 10px 0;
    padding: 0 5px;

    > .header {
      font-size: 16px;
      line-height: 1.28571429em;
    }

    > .underheader {
      color: #bababc;
    }
  }

  .expanded-order {
    display: flex;
    margin-left: 10px;

    > .id {
      margin: 0 5px 0 0;
    }

    > .date {
      margin-right: 10px;
    }

  }
}
