.InvoiceElement {
    width: 180px;
    margin: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 10px;
    display: inline-block;
    border-radius: 4px;

    > .body {

        > .name {
            font-weight: bold;
        }

        > .header {
            display: flex;
            align-items: baseline;


            > .name {
                cursor: pointer;
                font-size: 18px;
                font-weight: bold;
                margin-right: 10px;
                flex: 1;
            }

            > .edit-icon {
                transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
                color: #8e8e8e;
                margin-left: 3px;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }

    }

    > .date {

    }
}