.PopOverIcon{
    > .over {
        background: white;
        position: absolute;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        padding: 11px;
        width: 300px;
        height: 200px;
        font-size: 15px;
        margin-top: 5px;
        white-space: pre-line;
    }
}
