.UvPackingPrintCheck {
    margin-right: 20px;

    > .fullImage{
        cursor: pointer;
        position: absolute;
        z-index: 1000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > .loader{
        position: absolute;
        z-index: 1000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8);
    }

    > .header {
        margin-top: 14px;
        margin-bottom: 3px;
    }

    > .content{
        display: flex;

        > .image {
            cursor: pointer;
            height: 35px;
            border: 1px solid black;
        }

        > .buttons{
            margin-left: 5px;
        }
    }
}
