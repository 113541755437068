html {
  height: auto;
  margin: 0;
  padding: 0;
}

body {
  height: auto;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*height: 100%;*/
  height: auto;

  font-family: "Open Sans", sans-serif;
  /*letter-spacing: 2.2px;*/
  font-size: 14px;
  color: #211f1f;
  /*text-transform: uppercase;*/
}
