.PricingConfig{
    .create-icon {
        font-size: 25px;
        cursor: pointer;
        margin: 20px 20px 20px 0;
    }

    > .create-pricing-list{
        margin: 50px;
    }
}