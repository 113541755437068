.UserSearchByName {
    margin-top: 20px;

  > .new-items {
    margin-top: 20px;

    > .new-item {
      margin: 5px 0;
    }
  }

  > .top {
    display: flex;
    align-items: center;

    > .or {
      margin: 0 24px;
    }
  }

  > .button {
    margin-top: 20px
  }

  .autocomplete {
    position: absolute;
    margin-left: 78px;
    background: white;
    padding: 10px;
    border: 1px solid #dededf;
    width: 427px;
    z-index: 500;

    > .autocomplete-item {
      cursor: pointer;
      padding: 3px 0;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
