@import '../Variables';

.HeaderComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 40px;

  > .left {
  }

  > .middle {
    display: flex;

    > .menu-item {
      text-transform: uppercase;
      font-family: $font-family-poppins;
      font-size: 11px;
      font-weight: 500;
      color: $color-black;
      letter-spacing: .2em;
      margin: 0 27px;
      cursor: pointer;
    }
  }

  > .right {
    display: flex;
    align-items: center;
    height: 100%;

    > .menu-item {
      font-family: $font-family-open-sans;
      letter-spacing: 2.2px;
      font-size: 11px;
      color: $color-black-light;
      text-transform: uppercase;
    }

    > .history-cart-item {
      display: flex;
      align-items: center;
      font-size: 16px;

      cursor: pointer;
    }

    > .menu-cart-item {
      display: flex;
      align-items: center;
      margin: 0 30px;

      > .cart-item-icon {
        font-size: 20px;
      }



      > .cart-item-amount {
        font-family: $font-family-poppins;
        font-size: 12px;
        letter-spacing: .1em;
        margin-left: 5px;
      }
    }
  }
}