.PackingScreen {
  display: flex;
  position: absolute;
  top: 120px;
  bottom: 30px;
  left: 30px;
  right: 30px;


  > .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;

    > .button {
      user-select: none;
      font-size: 40px;
      background: black;
      color: white;
      padding: 15px;
      cursor: pointer;

      &.disabled {
        background: gray;
        cursor: crosshair;
      }
    }
  }

  > .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    //background: #1d5d90;


    > .currentOrder {
      font-size: 30px;
      font-weight: bold;

      > .orderNameAndAddress {
        display: flex;
        align-items: center;

        > .address {
          font-weight: normal;
          font-size: 14px;
          border: 1px solid #b5b4b4;
          padding: 10px;
          margin-left: 20px;
        }
      }

      > .refresh {
        position: absolute;
        right: 16px;
        top: 0px;
        background: #2185d0;
        color: white;
        font-size: 30px;
        padding: 2px 5px 7px 7px;
        cursor: pointer;
      }

      > .counter {
        position: absolute;
        left: 16px;
        top: 0px;
        color: black;
        font-size: 14px;
        padding: 2px 5px 7px 7px;
      }
    }

    > .itemsToPack {
      flex: 1;
      margin-top: 20px;

      > .empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
    }

    > .counter {
      font-weight: bold;
      font-size: 15px;
    }
  }
}
