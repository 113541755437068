.OrderScreen {
  margin: 30px 20px;

  > .content {
    display: flex;
    flex-wrap: wrap;

    > .left {

      > .panel{
        margin-bottom: 40px;
      }

      > .order-info {
        display: flex;
        margin-bottom: 45px;

        &.suborder {
          background: #93d0fd;
          padding: 10px;
          border-radius: 5px;
        }

        > .info {
          > .order-nr {
            font-weight: bold;
            font-size: 20px;
          }

          > .remake {
            background: purple;
            color: white;
            font-weight: bold;
          }

          > .date {
            color: gray;
          }
        }

        > .status {
          margin-left: 30px;
          font-size: 16px;
          font-weight: bold;
          border: 1px solid black;
          display: inline-table;
          padding: 5px;

          &.red {
            background: red;
            color: white;
          }

          &.green {
            background: #13a713;
            color: white;
          }

          &.blue {
            background: #2185d0;
            color: white;
          }

        }

      }

    }

    > .middle {
      flex: 1 1;
      display: flex;
      margin: 0 50px;
      flex-direction: column;

      > .assistant {
        margin-bottom: 30px;
        padding: 0 20px;
      }

      > .multiOrder {
        margin-bottom: 30px;
      }

      > .menu {
        align-self: center;
      }

      > .rendered-element {
        margin-top: 50px;
      }
    }

    > .right {

    }
  }


  .fill {
    flex: 1;
  }

}
