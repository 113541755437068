.MaterialConfig2 {

  padding: 10px;

  &.editing {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  > .header {

    display: flex;
    align-items: center;
    margin: 20px 0;

    > .image {
      > .checkmark {
        background: white;
        display: inline-flex;
        padding: 2px;
        position: absolute;
        z-index: 1000;
        margin-top: 50px;
        margin-left: 49px;

        &.hide {
          display: none;
        }
      }
    }

    > .name-col {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 30px;

      &.name {
        width: 150px;
      }

      > .name {
        margin-bottom: 0;
      }
    }

    > .label-with-value {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 30px;

      > .label {
        color: rgb(125, 125, 125);
        font-size: 11px;
      }

      > .value {
        display: inline-flex;
      }

      &.category {
        width: 45px;
      }
    }
  }

  > .editing {

  }
}