.OrderItemStatusButton {
    margin: 0 5px;
    padding: 1px 5px;
    font-weight: bold;
    cursor: pointer;

    .span {
        position: relative;
        background: black;
        top: -10px;
        right: -5px;
        font-size: 13px;
        padding: 0px 3px;
    }

    &.blue {
        background: #44b5ee;
        color: white;
    }

    &.red {
        background: #F44336;
        color: white;
    }

    &.green{
        background: #66bb6a;
        color: white;
    }
}
