.SalesItem {

  > .Item {
    display: flex;

    &:hover {
      background: #d0d0d0;
    }

    .col {
      width: 100px;
    }

    .bla {
      width: 200px;
    }
  }

}

