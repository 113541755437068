.VerificationComponent {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 15px;
    margin: 20px 0;

    > .main {

        > .header {
            display: flex;
            padding: 5px;
            border-bottom: 1px solid;
        }

        > .items {
            > .ok{
                margin: 5px 0;
            }
        }
    }
}