.action {
    font-size: 30px;
    font-weight: bold;
}


.rebutConfirm {
    display: flex;
    align-items: center;

    > .buttonSpacer {
        margin-left: 15px;
    }
}

.OrderItemStatusModalSection {
    margin-top: 25px;
    border: 1px solid #e0e0e0;
    border-top: none;
    padding: 10px;
    padding-top: 0;

    > .title {
        color:white;
        background: black;
        padding: 5px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }



    > .list {
        display: flex;
        align-items: flex-end;
        margin-left: 5px;

        > .quantity {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
        }

        .listItem {
            margin-right: 30px;

            &.light {
                opacity: 0.5;
            }

            > .label{

            }

            > .value{
                font-size: 20px;
                font-weight: bold;
            }
        }
    }


}
