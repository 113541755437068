.OrderShipping {
  width: 250px;

  .autocomplete {
    position: absolute;
    margin-left: 78px;
    background: white;
    padding: 10px;
    border: 1px solid #dededf;
    width: 427px;
    z-index: 500;

    > .autocomplete-item {
      cursor: pointer;
      padding: 3px 0;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .item {
    margin: 5px;

    &.fill {
      flex: 1;
    }

  }


  .head {
    display: flex;
    margin-bottom: 10px;
    padding: 4px;
    border-bottom: 2px solid #dededf;

    > .text {
      font-size: 15px;
      font-weight: bold;

      > .remote {
        color: white;
        padding: 2px 6px;
        position: relative;
        right: -68px;

        &.red {
          background: #F44336;
        }

        &.green {
          right: -33px;
          background: #4CAF50;
        }

        &.yellow {
          right: -47px;
          background: #FFC107;
        }
      }
    }

    > .fill {
      flex: 1;
    }

    > .change {
      color: rosybrown;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }

  .extra {
    margin: 10px 0;
    padding: 0 5px;

    .link {
      cursor: pointer;
    }
  }

  .body {
    margin: 10px 0;
    padding: 0 5px;

    > .header {
      font-size: 16px;
      line-height: 1.28571429em;
    }

    > .underheader {
      color: #bababc;
    }
  }

  .expanded-order {
    display: flex;
    margin-left: 10px;

    > .id {
      margin: 0 5px 0 0;
    }

    > .date {
      margin-right: 10px;
    }

  }


}