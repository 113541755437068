.ItemWeight {
    display: flex;
    align-items: center;
    background: #f5b3b3;
    padding: 20px;

    > .name {
        margin-right: 10px;
    }
}
