.OrderPacker {
  position: relative;

  .error {
    color: red;
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: aliceblue;
    z-index: 100;
  }

  .header {
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap;
    max-width: 280px;

    > .spacer {
      flex: 1;
    }
  }

  .link {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .link:hover {
    color: black;
  }

  .box-content {
    display: flex;
    font-weight: initial;
  }

  .box-content-divider {
    width: 5px;
    background: #1d5d90;
    margin-right: 10px;
  }

  .box {
    margin-bottom: 25px;

    > .title {
      display: flex;

      > .title-label {
        flex: 1;
      }

      > .remove {
        font-size: 15px;
        color: red;
        cursor: pointer;
        font-weight: bold;
      }
    }

    > .box-body {
      display: flex;

      > .grouper {
        width: 5px;
        background: #1d5d90;
      }

      > .content {
        > .add-item {
          margin-top: 15px;
          margin-bottom: 15px;
          margin-left: 10px;
        }

        > .items {
          font-weight: initial;

          > .item-in-box {
            display: flex;
            align-items: center;
            margin-left: 10px;

            > .remove {
              font-size: 15px;
              margin-left: 10px;
              color: red;
              cursor: pointer;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
