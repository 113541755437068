.TimelineScreen {
    padding-top: 50px;
    width: 600px;
    margin: 0 auto;
    > .Item {
        margin-bottom: 20px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 8px;
        border-radius: 5px;

        > .head {
            display: flex;
            align-items: baseline;

            > .date {
                color: cadetblue;
                margin-right: 5px;
            }

            > .who {
                color: black;
                font-size: 10px;
                //font-weight: bold;
            }

            > .meta{
                margin-left: 10px;
                cursor: pointer;
            }
        }

        > .what {
            margin: 3px 0 0 10px;
        }

        > .meta{
            margin-left: 10px;
        }
    }
}