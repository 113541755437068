.TrustScreen {
  margin: 100px 20%;

  > .users {
    > .user {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      padding: 20px;
      margin: 25px 0;

      > .labels {
        display: flex;

        > .label{
          margin-right: 10px;
        }
      }

      > .online {
        margin-top: 50px;
      }
    }
  }
}
