.OrderPiece {
  display: flex;
  //border-bottom: 1px solid black;

  > .raw {
      flex: 1;
    > .line1 {
      display: flex;

      > .title {
        font-weight: bold;
      }

      > .price {
        margin-left: 5px;
      }
    }

    > .instructions {
      margin-left: 20px;
      margin-top: 5px;

      > .key {
        font-weight: bold;
      }

      > .value {
        margin-left: 5px;
        margin-bottom: 20px;
        //font-family: monospace;
        white-space: pre-line;
      }
    }
  }

  > .transformed {
    flex: 2;
  }


}