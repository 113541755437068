.OrderPieceElement {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;

  > .order-item-photos{
    margin: 20px 0 30px 0;
  }

  .item {
    margin: 10px 0;

    > .client-request{
      background: black;
      padding: 10px;
      color:white;
      font-size: 15px;
      border-radius: 4px;
    }

    > .label {
      padding: 3px
    }
  }

  &.disableAll{
    pointer-events: none;
    user-select: none;
    cursor: crosshair;
  }

  > .header {
    display: flex;

    > .name {
        flex:1;
    }

    > .discount {
      margin: 0 5px;
      cursor: pointer;
      color: blue;
    }

    > .delete{
      color:red;
      cursor: pointer;
    }

    > .ct{
      cursor: pointer;
      margin-right: 20px;
    }
  }
}
