.main {

}

.checkboxContainer{
    display: block;
    position: relative;
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxCheckmark{
    position: absolute;
    top: 6px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #090b13;
    /*background-color: #090b13;*/
    border-radius: 50%;
}

.checkboxContainer:hover input ~ .checkboxCheckmark {
    background-color: #a47f5144;
}

.checkboxContainer input:checked ~ .checkboxCheckmark {
    /*background-color: #090b13;*/
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkboxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkboxContainer input:checked ~ .checkboxCheckmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.checkboxContainer .checkboxCheckmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #090b13;
}

.checkboxValue{
    padding-top: 3px;
    padding-left: 13px;
    font-size: 25px;
}

.checkboxValueLarge{
    composes: checkboxValue;
    font-size: 13px;
    margin-left: 0;
}

.labelSubtitle {
    color: #cbc9c7;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 16px;
    white-space: nowrap;
}