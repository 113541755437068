@import "../Variables";

.AuthenticateScreen {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 7rem;

  flex: 1;



  > .wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 320px;

    > .title {
      text-align: center;
      font-family: $font-family-poppins;
      font-size: 35px;
      line-height: 1.142857142857143em;
      font-weight: 500;
      letter-spacing: .05em;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    > .form-error {
      color: red;
    }

    > .input {
      margin-top: 30px;
    }

    > .login-wrapper {
      display: flex;
      align-items: center;
      margin-top: 15px;

      > .forgot-password {
        flex: 1;
      }
    }

    > .login-hr {
      border-bottom: 1px solid $color-light-gray;
      margin: 15px 0;
    }

    > .register-button {
      align-self: center;
      margin-top: 15px;
    }

    > .register-text {
      align-self: center;
      color: $color-gray;
      letter-spacing: 0.02px;
      margin-top: 10px;
    }
  }
}