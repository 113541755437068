$color-black: #000;
$color-black-light: #211f1f;
$color-gold: #9d8561;
$color-gray: #a7a7a7;
$color-light-gray: #ddd;

$font-weight-menu: 500;
$font-size-menu: 11px;

$font-family-poppins: Poppins,sans-serif;
$font-family-open-sans: "Open Sans", sans-serif;
