.ExternalConfigurator {
    margin-bottom: 30px;

    > .order {

        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        padding: 20px;
        display: inline-block;
        width: 300px;

        > .title {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 30px;
        }

        > .pre-title{
            font-size: 11px;
        }

        .react-date-picker__wrapper {
            display: flex;
            border: thin solid #dededf;
            padding: 6px;
            border-radius: 5px;
        }

        > .item {
            margin: 10px 0;

            > .label {
                padding: 3px
            }
        }

        > .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
        }
    }
}