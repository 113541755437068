.OrderBadgeFilters {
  display: flex;
  margin-left: 20px;

  > .item {
    display: flex;
    width: 36px;
    height: 36px;
    border: 1px solid rgba(34,36,38,.15);
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;

    align-content: center;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &.selected{
      background: #4CAF50;
      color: white;
    }
  }
}

.OrderBadgeDisplay {
  display: flex;
  flex:1;
  width: 105px;

  > .item {
    display: flex;
    width: 25px;
    height: 25px;
    border: 1px solid rgba(34,36,38,.15);
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;

    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
