.AwbShipment {
  display: flex;

  > .add-box {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    cursor: pointer;
  }

  > .boxes {
    display: flex;
    flex-wrap: wrap;

    > .input-list {
      display: flex;
      flex-direction: column;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      margin: 20px;
      padding: 20px;
      width: 135px;

      &.set {
        box-shadow: 0 1px 3px #4CAF50, 0 1px 2px #4CAF50;
      }

      > .item {
        margin: 5px 0;
        width: 67px;
      }
    }
  }

}