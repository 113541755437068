.EmbossWorkItem{
    width: 500px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 10px;

    > .header {
        display: flex;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        > .text {
            margin-right: 15px;

            &.large {
                flex: 1;
            }
        }

        > .status {
            padding: 4px;
            background: #f44336;
            color: #fff;

            &.done {
                background: #66bb6a;
            }
        }
    }

    > .lines {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        > .line {
            text-align: center;
            border-bottom: 1px solid #cfcfcf;
            padding: 5px 0;
        }
    }
}
