.InvoiceEditor {


  > .head {
    display: flex;

    > .left {
      flex: 1;
      display: flex;

      .logo {
        width: 250px;
      }
    }

    > .right {
      flex: 1;
      text-align: right;
    }
  }

  > .invoice-head {
    > .title {
      font-size: 35px;
    }

    > .error{
      color: red;
      font-weight: bold;
    }

    > .info {
      display: flex;

      > .right {
        margin-left: 100px;
      }
    }
  }

  > .invoice-items {
    margin-top: 80px;

    .row {
      display: flex;
      padding: 6px 0;
      border-bottom: 1px solid #9a9a9a;

      &.head {
        font-weight: bold;
        font-size: 16px;
      }

      &.noborder {
        border: none;
      }
    }
  }


}

.input-inv {
  text-align: right;
  width: 100%;
  border: none;

  &.normal {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.invoice-history {
  margin: 10px 0;
  font-size: 12px;
  color: gray;
  border-left: 1px solid lightgray;
  padding-left: 5px;
}
