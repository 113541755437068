@import './Variables';

.App {
  -webkit-font-smoothing: antialiased;
}

.delete-button {
  font-weight: bold;
  color: rosybrown;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: red;
  }
}

a {
  text-decoration: none;
}

@font-face {
  font-family: goudyttf;
  src: url('/fonts/goudy.ttf');
}

@font-face {
  font-family: goudycoursiveotf;
  src: url('/fonts/goudycoursive.otf');
}

@font-face {
  font-family: traftonscriptttf;
  src: url('/fonts/traftonscript.ttf');
}
