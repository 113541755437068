.InvoicesScreen {
    padding: 20px;
    display: flex;
    flex-direction: column;

    > .header {
        display: flex;
        margin-bottom: 20px;

        > .searchField {
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    > .search-prompt {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        > .label {
            font-weight: bold;
            font-size: 16px;
            margin-right: 20px;
        }
    }

    > .create-icon{
        font-size: 30px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    > .list {
    }

    > .pagination {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    > .invoice {
    }

    .table-actions{
        margin: 0 3px;
        cursor: pointer;
    }
    .table-actions:hover{
       font-weight: bold;
    }
}
