.ProductPersonalisation {
    margin-bottom: 30px;

    .image {
        margin-right: 10px;
    }

    .flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .mockWrapper {
        margin-bottom: 20px;
    }

     .fullImage{
        cursor: pointer;
        position: absolute;
        z-index: 1000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .toggle {
        padding: 5px;
        background: #f7f7f7;
        margin-right: 30px;
        margin-bottom: 20px;
        width: 100%;
        border-radius: 8px;
    }

    .toggleContent {
        //margin-left: 20px;
    }

    .item {
        margin: 10px 0;
    }

    > .label {
        padding: 3px
    }
}
