.awb {


  > .item {

    padding: 5px;

    &.late {
      background: #ffccc8;
    }

    &.sent {
      background: #ccebc2;
    }

    > .row {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      > .nr {
        margin-right: 10px;
      }

      > .status {

        display: inline-flex;
        padding: 3px 5px;
        margin-right: 10px;

        &.late {
          background: #f44336;
        }
      }

      > .more {
        cursor: pointer;
        font-weight: bold;
      }


    }

    > .lastEvent {
      margin-left: 96px;
    }

    > .allEvents {
      > .goingTo {
        margin-bottom: 10px;
      }
    }
  }
}

.OrderItem {
  margin: 1rem 0;
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;

  > .awb {


    > .item {

      padding: 5px;
      border-bottom: 4px solid white;

      &.late {
        background: #ffccc8;
      }

      &.sent {
        background: #ccebc2;
      }

      > .row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        > .nr {
          margin-right: 10px;
        }

        > .status {

          display: inline-flex;
          padding: 3px 5px;
          margin-right: 10px;

          &.late {
            background: #f44336;
          }
        }

        > .more {
          cursor: pointer;
          font-weight: bold;
        }


      }

      > .lastEvent {
        margin-left: 96px;
      }

      > .allEvents {
        > .goingTo {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.over {
    border: 1px solid #afafaf;
  }

  > .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 5px;

    > .profit-stats {
      margin-left: 50px;
      font-size: 12px;
      //font-weight: bold;

      > .item {

        &.red {
          color: red
        }

        &.green {
          color: green;
        }
      }
    }

    > .refresh {
      font-size: 15px;
      cursor: pointer;
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-right: 20px;
    }

    &.red {
      background: #FBE9E7;
    }

    > .status {
      font-size: 14px;
      font-weight: bold;
      padding: 2px 6px 4px 6px;
      border-radius: 5px;

      margin-right: 20px;

      &.red {
        background: red;
        color: white;
      }

      &.green {
        background: #13a713;
        color: white;
      }

      &.blue {
        background: #2185d0;
        color: white;
      }

    }

    > .clicker {
      cursor: pointer;
      margin: 0px 6px;
      border: 1px solid gray;
      padding: 1px 3px;
      border-radius: 5px;

      &.green {
        background: #4CAF50;
        color: white;
        border-color: #4caf4f;
      }

      &.red {
        background: red;
        color: white;
        border-color: #4caf4f;
      }
    }

    > .status-dropdown {
      margin: 10px;
    }

    > .created {
      margin: 10px;
      width: 180px;
    }

    > .name {
      margin: 10px;
      width: 130px;
    }

    > .production-status {
      flex: 1;
      margin: 10px;
    }

    > .expand {
      cursor: pointer;
    }
  }

  &.expanded {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &.shiped {
    border-left: 3px solid #5cde5c;
  }

  &.red {
    border-left: 3px solid #de485a;
  }
}

//.OrderItem:hover {
//  background: #efefef;
//}
