.CutCard {
    display: inline-flex;
    flex-direction: column;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    >.header{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .CutCard_inputHead {
        margin-top: 25px;
        margin-bottom: 3px;
        font-weight: bold;
    }

}
