.PiecesSoldComponent {
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  //padding: 30px;
  //margin: 20px 0;
  //display: flex;
  //max-height: 400px;
  margin-top: 20px;

  > .stats {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    > .stat {
      margin: 20px 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      padding: 15px;
      padding-bottom: 0px;

      > .text {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-size: 1em;
        font-weight: 700;
        color: rgba(0, 0, 0, .87);
        text-transform: uppercase;
      }
    }
  }

  > .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    > .header {
      display: flex;
      padding: 5px;
      border-bottom: 1px solid;
    }

    > .items {
      > .ok {
        margin: 5px 0;
      }
    }
  }
}

.ui.mini.statistic > .value {
  font-size: 1.5rem !important;
}

.ui.statistic > .label {
  font-size: 0.7em;
}

.ui.mini.statistic > .value {
  font-size: 1.3rem !important;
}

.ui.statistic+.ui.statistic {
  margin: 0 0 0 1em;
}