.TrasferLayer {
  margin: 30px 0;
  border: 1px solid rgba(34, 36, 38, .1);
  border-radius: 4px;
  padding: 13px 16px;

  > .panes {
    display: flex;


    > .pane {

      &.full {
        flex: 1;
      }

      &.arrow {
        font-size: 80px;
        color: #e9e9e9;
      }

      > .head {
        display: flex;
        align-items: center;
      }


      > .content {
        margin-top: 40px;
      }

    }
  }

  .itemDetails{
    display: inline-flex;
    background: black;
    color: white;
    padding: 5px;
  }

  .transferTo {
    margin-top: 30px;
    display: flex;
  }

  .rowItem {
    display: flex;
    align-items: center;
    margin: 40px 0;

    > .title{
      font-weight: bold;
      font-size: 15px;
      margin-right: 30px;
    }
  }
}
